const renewalDocumentTypes = {
  dap: "dap",

  ordoPpc: "ordo-ppc",
  ordoAvf: "ordo-avf",
  ordoAero: "ordo-aero",
  ordoOdysp: "ordo-odysp",
  ordoAspiTracheo: "ordo-aspi-tracheo",
  ordoF3: "ordo-f3",
  ordoF3Covid: "ordo-f3-covid",
  ordoF4: "ordo-f4",
  ordoF5: "ordo-f5",
  ordoF7: "ordo-f7",
  ordoF8: "ordo-f8",

  ordoF12: "ordo-f12", // FORFAIT 3 + 4
  ordoF15: "ordo-f15", // FORFAIT 3 + 5
  ordoF21: "ordo-f21", // FORFAIT 3 + 7
  ordoF24: "ordo-f24", // FORFAIT 3 + 8

  dep: "dep",
  depVni: "dep-vni",
  depF4: "dep-f4", // DEP + FORFAIT 4
  depF5: "dep-f5", // DEP + FORFAIT 5
  depF7: "dep-f7", // DEP + FORFAIT 7
  depF8: "dep-f8", // DEP + FORFAIT 8
  depF13: "dep-f13", // DEP + FORFAIT 5 + 1
  depF14: "dep-f14", // DEP + FORFAIT 5 + 2
  depOdysp: "dep-odysp", // DEP PEDIA ODYSP

  dapDocumentTypes: ["dap", "ordo-ppc"],
  depDocumentTypes: ["dep", "dep-vni", "dep-f4", "dep-f5", "dep-f7", "dep-f8", "dep-f13", "dep-f14", "dep-odysp"],
  depWithSeveralTreatmentsDocumentTypes: ["dep-f4", "dep-f5", "dep-f7", "dep-f8", "dep-f13", "dep-f14", "dep-odysp"]
};

module.exports = renewalDocumentTypes;
