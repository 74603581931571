const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/modules/dashboard/prescriber/index.prescriber");

export default [
  {
    path: "",
    name: "dashboard",
    component: Dashboard,
    props: true
  }
];
