import { getPatientIdByPhoneNumb } from "../_api";

const state = {
  patientId: ""
}

const getters = {
  patientId: state => state.patientId
}

const mutations = {
  SET_PATIENTID: (state, patientId) => {
    state.patientId = patientId;
  }
}

const actions = {
  getPatientId(context, tel) {
    return new Promise((resolve, reject) => {
      getPatientIdByPhoneNumb(tel)
        .then(res => {
          context.commit("SET_PATIENTID", res.data.body._id);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
export default { state, getters, mutations, actions, namespaced: true };