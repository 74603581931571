import Vue from "vue";
import VueRouter from "vue-router";

import dashboard from "./routes/prescriber/dashboard";
import app from "./routes/app";
import login from "./routes/prescriber/login";
import patients from "./routes/prescriber/patients";
import conversations from "./routes/prescriber/conversations";
import footer from "./routes/footer";
import oald from "./routes/prescriber/oald";
import publications from "./routes/prescriber/publications";

import i18n from "./middleware/i18n";
import auth from "./middleware/auth";
import middlewarePipeline from "./middleware/middleware-pipeline";

Vue.use(VueRouter);

const routes = [...conversations, ...login, ...oald, ...patients, ...publications, ...dashboard, ...app, ...footer];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:locale([a-zA-Z]{2})?",
      component: {
        render: c => c("router-view")
      },
      children: routes
    }
  ]
});

router.beforeEach((to, from, next) => {
  const middleware = [i18n, auth];
  const context = {
    to,
    from,
    next
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
