import http from "@/services/http";

const getPatientsCount = (query = {}) => {
  return new Promise((resolve, reject) => {
    http
      .post("/patients/count", query)
      .then(res => {
        if (!res || !res.data || !res.data.body) {
          return resolve();
        }
        return resolve(res.data.body);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default getPatientsCount;
