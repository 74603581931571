<template>
  <div
    class="information-banner padding--xs print--display--none"
    :class="`information-banner--${type}`"
    :data-test="dataTest"
  >
    <slot name="icon">
      <component :is="iconType.icon" :color="iconType.iconColor" width="28" height="28" />
    </slot>
    <p class="margin--top--null margin--bot--null margin--left--xs"><slot /></p>
  </div>
</template>

<script>
import { iconError, iconCheck, iconMessage } from "@ui/components/icons";

export default {
  name: "InformationBanner",
  components: {
    iconError
  },
  props: {
    type: {
      type: String,
      required: true
    },
    scrollToTop: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconType() {
      switch (this.type) {
        case "positive":
          return { icon: this.getIcon("check"), iconColor: "uc-positive" };
        case "caution":
          return { icon: this.getIcon("error"), iconColor: "uc-caution" };
        case "warning":
          return { icon: this.getIcon("error"), iconColor: "uc-warning" };
        case "info":
          return { icon: this.getIcon("message"), iconColor: "ac-3" };
        default:
          return false;
      }
    },
    dataTest() {
      return this.type === "positive" ? "banner-success" : "banner-error";
    }
  },
  methods: {
    scroll() {
      const navHeight = document.getElementById("app-header")?.offsetHeight || 0;
      const scrollPos = this.$el.offsetTop - navHeight;
      window.scrollTo({ top: scrollPos });
    },
    getIcon(defaultIcon) {
      const iconName = this.icon || defaultIcon;
      if (iconName === "message") {
        return iconMessage;
      }
      if (iconName === "error") {
        return iconError;
      }
      if (iconName === "check") {
        return iconCheck;
      }
    }
  },
  updated() {
    if (this.scrollToTop) {
      this.scroll();
    }
  },
  mounted() {
    if (this.scrollToTop) {
      this.scroll();
    }
  }
};
</script>
