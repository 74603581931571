<template>
  <div>
    <div v-if="loading">
      <h1>{{ $t(`${i18nKey}.h1`) }}</h1>
      <loader />
    </div>
    <information-banner type="warning" v-if="error">
      {{ $t(`${i18nKey}.p--error1`) }}
      <router-link :to="'/connexion'"> {{ $t(`${i18nKey}.router-link`) }}</router-link> <br />
      {{ $t(`${i18nKey}.p--error2`) }}
    </information-banner>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { setAppUser } from "@/services/auth";
import { EventBus } from "@/services/event-bus";
import http from "@/services/http";
import Loader from "@ui/components/loader";
import InformationBanner from "@ui/components/banners/InformationBanner";

const i18nKey = "login.validate-legacy-prescriber";

/**
 * @PILOT
 * Remove component for validating V2 prescriber
 */
export default {
  name: "ValidateV2Prescriber",
  components: {
    Loader,
    InformationBanner
  },
  data() {
    return {
      i18nKey,
      loading: true,
      error: false
    };
  },
  methods: {
    ...mapActions({
      setUser: "login/setUser",
      disconnectConnectedUser: "credentials/disconnectConnectedUser"
    }),
    setError() {
      this.loading = false;
      this.error = true;
    }
  },
  mounted() {
    http
      .get("/validate-legacy-prescriber")
      .then(result => {
        const user = result && result.data && result.data.body ? result.data.body : null;
        if (!user) {
          return this.disconnectConnectedUser().finally(() => {
            this.$router.push({ name: "login" }).catch(() => {});
          });
        }
        /* Set a timeout to avoid screen flashing and make sure user is informed of what is happening */
        setTimeout(() => {
          setAppUser(user)
            .then(() => {
              EventBus.$emit("login");
              this.setUser(user);
              this.$router.push({ name: "dashboard" }).catch(() => {});
            })
            .catch(errLocalUser => {
              this.setError();
              console.log(errLocalUser);
            });
        }, 3000);
      })
      .catch(err => {
        this.setError();
        console.log(err);
      });
  }
};
</script>
