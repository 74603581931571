import i18n from "@/i18n";
import validateLegacyPrescriber from "@/modules/login/validateLegacyPrescriber";

const i18nKey = "router.prescriber.login";

const CredentialsPage = () => import(/* webpackChunkName: "public" */ "@/modules/credentials");
const login = () => import(/* webpackChunkName: "public" */ "@/modules/login/index.prescriber");

export default [
  {
    path: "register",
    component: CredentialsPage,
    children: [
      {
        path: "prescriber/:token",
        alias: "medecin/:token",
        name: "registerPrescribers",
        component: CredentialsPage,
        meta: {
          title: i18n.t(`${i18nKey}.title--register`),
          public: true
        }
      }
    ]
  },
  {
    path: "reset-password",
    component: CredentialsPage,
    children: [
      {
        path: "prescriber/:token",
        alias: "prescripteur/:token",
        name: "resetPasswordPrescribers",
        component: CredentialsPage,
        meta: {
          title: i18n.t(`${i18nKey}.title--resetPassword`),
          public: true
        }
      }
    ]
  },
  {
    path: "sign-in",
    alias: "connexion",
    name: "login",
    component: login,
    meta: {
      title: i18n.t(`${i18nKey}.title--signIn`),
      public: true
    }
  },
  /**
   * @PILOT
   * Remove route for authenticating V2 Prescriber
   */
  {
    path: "validate-legacy-prescriber",
    name: "validate-legacy-prescriber",
    component: validateLegacyPrescriber,
    meta: {
      title: i18n.t(`${i18nKey}.title--validateLegacyPrescriber`),
      public: true
    }
  }
];
