import moment from "moment";
import * as api from "../_api";
import { getPatientsCount } from "../../common/_api";

const state = {
  patientsCount: null,
  countRenewalsToSign: null,
  countRenewalsToPrint: null,
  unreadCount: null,
  banner: null,
  news: []
};

const getters = {
  patientsCount: state => state.patientsCount,
  countRenewalsToSign: state => state.countRenewalsToSign,
  countRenewalsToPrint: state => state.countRenewalsToPrint,
  banner: state => state.banner,
  news: state => state.news
};

const mutations = {
  SET_PATIENTS_COUNT: (state, patientsCount) => {
    state.patientsCount = patientsCount;
  },
  SET_RENEWALS_TO_SIGN_COUNT: (state, countRenewalsToSign) => {
    state.countRenewalsToSign = countRenewalsToSign;
  },
  SET_RENEWALS_TO_PRINT_COUNT: (state, countRenewalsToPrint) => {
    state.countRenewalsToPrint = countRenewalsToPrint;
  },
  SET_BANNER: (state, banner) => {
    state.banner = banner;
  },
  SET_NEWS: (state, news) => {
    state.news = news;
  }
};

const actions = {
  getPatientsCount(context) {
    return new Promise((resolve, reject) => {
      getPatientsCount({ query: { division: "R", contractStatus: ["active"] } })
        .then(res => {
          const count = res?.count || 0;
          context.commit("SET_PATIENTS_COUNT", count);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRenewalsToPrintCount(context) {
    return new Promise((resolve, reject) => {
      getPatientsCount({
        query: { renouStates: ["late", "toPrint", "initialToPrint"], depDateEnd: moment().add(4, "month").toISOString() }
      })
        .then(res => {
          context.commit("SET_RENEWALS_TO_PRINT_COUNT", res?.count || 0);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRenewalsCount(context) {
    return new Promise((resolve, reject) => {
      getPatientsCount({ query: { renouStates: ["late", "toSign", "initialToPrint"], contractStatus: "active" } })
        .then(res => {
          context.commit("SET_RENEWALS_TO_SIGN_COUNT", res?.count || 0);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getBanner(context) {
    return new Promise((resolve, reject) => {
      api
        .getBanner()
        .then(res => {
          context.commit("SET_BANNER", res?.data?.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNews(context) {
    return new Promise((resolve, reject) => {
      api
        .getNews()
        .then(res => {
          context.commit("SET_NEWS", res?.data?.body);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
