import http from "@/services/http";

/**
 * @param {Object} params
 * @returns {<Object>} unread count
 */

export const getCountUnreadConversation = params =>
  http.get("/conversations/unread/count", {
    params
  });

export const getBanner = () => http.get("/publications/active-banner");

export const getNews = () => http.get("/publications/active-news");
