import http from "@/services/http";

export const createOaldRequest = data => http.post(`/oald`, data);

/**
 * @param {Object} data
 * @param {String} id
 * @returns {<Object>} filled document
 */
export const signDocumentFromOALD = data => http.post(`/oald/sign-document`, data);
