import * as api from "../_api";

const state = {
  supportPhone: null
};

const getters = {
  supportPhone: state => state.supportPhone
};

const mutations = {
  SET_SUPPORT_PHONE: (state, supportPhone) => {
    state.supportPhone = supportPhone;
  }
};

const actions = {
  addSupportTicket(context, form) {
    return new Promise((resolve, reject) => {
      const { email, phone, entityType, ...data } = form;
      const { user } = context.rootState.login;
      const supportTicket = {
        userInformations: {
          email,
          phone,
          entityType,
          screenResolution: {
            width: window.screen.width,
            height: window.screen.height
          }
        },
        url: window.location.href,
        ...data
      };
      /* Context where user is logged */
      if (user.isLoggedIn) {
        supportTicket.userInformations.poleIds = user.poleIds;
        supportTicket.userInformations.userId = user._id;
      }
      api
        .addSupportTicket(supportTicket)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchSupportPhone(context, poleId) {
    api.fetchSupportPhone(poleId).then(res => context.commit("SET_SUPPORT_PHONE", res?.data?.body?.supportPhone));
  }
};

export default { state, getters, mutations, actions, namespaced: true };
