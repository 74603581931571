import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.prescriber.conversations";

const ConversationsList = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/list/PrescriberConversationsList");
const ConversationsAdd = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/add/PrescriberConversationsAdd");
const ConversationsView = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/view/PrescriberConversationsView");

export default [
  {
    path: "messages",
    name: "conversationsList",
    component: ConversationsList,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  },
  {
    path: "messages/add",
    name: "conversationsAdd",
    component: ConversationsAdd,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  },
  {
    path: "messages/:id",
    name: "conversationsView",
    component: ConversationsView,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  }
];
