/* eslint-disable no-shadow */
import { searchForfaits, searchProducts, fetchForfaits } from "@/modules/common/_api";

const state = {
  steps: [],
  forfait: {},
  mapKeyIndex: {},
  currentStepIndex: 0
};

const getters = {
  steps: state => state.steps,
  currentStepIndex: state => state.currentStepIndex,
  forfait: state => state.forfait,
  mapKeyIndex: state => state.mapKeyIndex,
  isLastStep: state => state.steps[state.currentStepIndex].isEnd
};

const mutations = {
  SET_STEPS: (state, steps) => {
    state.steps = steps;
  },
  SET_CURRENT_STEP_INDEX: (state, currentStepIndex) => {
    state.currentStepIndex = currentStepIndex;
  },
  SET_FORFAIT: (state, forfait) => {
    state.forfait = forfait;
  },
  SET_MAP_KEY_INDEX: (state, mapKeyIndex) => {
    state.mapKeyIndex = mapKeyIndex;
  }
};

const actions = {
  searchForfaits(context, { division, input }) {
    return searchForfaits({ query: { input, division, active: true }, skip: 0, limit: 40, source: "local" });
  },
  searchProducts(context, input) {
    return searchProducts({ query: { divisions: context.state.currentDivision, input }, skip: 0, limit: 10, source: "local" });
  },
  fetchForfaits(context, query) {
    const params = { query };
    return new Promise((resolve, reject) => {
      fetchForfaits(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetStepsFromIndex(context, index) {
    /* Retrieve all informations filled from index to end for reset them */
    const { mapKeyIndex, forfait, steps } = context.state;
    const names = Object.keys(mapKeyIndex).filter(key => mapKeyIndex[key] > index);
    names.forEach(key => {
      delete forfait[key];
    });
    context.dispatch("updateForfait", forfait);
    context.dispatch("updateSteps", steps.slice(0, index + 1));
  },
  updateSteps(context, steps) {
    context.commit("SET_STEPS", steps);
  },
  updateForfait(context, forfait) {
    context.commit("SET_FORFAIT", forfait);
  },
  updateMapKeyIndex(context, mapKeyIndex) {
    context.commit("SET_MAP_KEY_INDEX", mapKeyIndex);
  },
  updateCurrentStepIndex(context, index) {
    context.commit("SET_CURRENT_STEP_INDEX", index);
  },
  resetAald(context) {
    context.commit("SET_CURRENT_STEP_INDEX", 0);
    context.dispatch("updateForfait", {});
    context.dispatch("updateSteps", []);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
