import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "helpers._constants.urlDivisionsParams";

export default [
  {
    params: localize(localPath, "respiratory.params"),
    code: "R",
    label: localize(localPath, "respiratory.label")
  },
  {
    params: localize(localPath, "diabete.params"),
    code: "D",
    label: localize(localPath, "diabete.label")
  }
];
