import { oaldDisplayPrescriptionAddressResource } from "@ui/helpers/_functions/displaySpecificResource";
import renewalDocumentTypes from "@common/constants/renewalDocumentTypes";
import { searchPatients, fetchUsers, fetchForfaits, uploadPrivateFile, fetchFile, fetchPatient, fillDocument } from "@/modules/common/_api";
import formatPatientName from "@/helpers/_functions/patient/formatPatientName";
import * as api from "../_api";

const { SECRETAIRE_POLE } = require("@common/constants/roles");

const state = {
  contract: {},
  currentPrescriptionAddresses: []
};

const getters = {
  contract: state => state.contract,
  currentPrescriptionAddresses: state => state.currentPrescriptionAddresses
};

const mutations = {
  SET_CONTRACT: (state, contract) => {
    state.contract = contract;
  },
  SET_CURRENT_PRESCRIPTION_ADDRESSES: (state, currentPrescriptionAddresses) => {
    state.currentPrescriptionAddresses = currentPrescriptionAddresses;
  }
};

const actions = {
  searchPatients(context, input) {
    return new Promise((resolve, reject) => {
      searchPatients({ query: { input }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSecretairePole(context) {
    return new Promise((resolve, reject) => {
      const $user = context.rootGetters["login/$user"];
      const { poleId } = $user;
      fetchUsers({
        limit: 1,
        query: { roleIds: [SECRETAIRE_POLE.id], poleIds: [poleId] }
      })
        .then(res => {
          const { results } = res.data.body;
          return results.length
            ? resolve(results[0])
            : reject({
                data: {
                  errorMessage:
                    "Vous n'avez pas encore de secrétaire de pôle. Pour continuer veuillez contacter le support à l'adresse suivante: bastideaccess@bastide-medical.fr."
                }
              });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCurrentPrescriptionAddresses(context) {
    const prescriptionAddresses =
      context.rootGetters["login/$user"] && context.rootGetters["login/$user"].prescriptionAddresses
        ? context.rootGetters["login/$user"].prescriptionAddresses.map(item => ({
            ...item,
            value: item._id,
            label: oaldDisplayPrescriptionAddressResource(item, context.rootGetters["login/$user"])
          }))
        : [];
    context.commit("SET_CURRENT_PRESCRIPTION_ADDRESSES", prescriptionAddresses);
  },
  fetchForfaits(context, query) {
    const params = { query };
    return new Promise((resolve, reject) => {
      fetchForfaits(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  resetContract(context) {
    context.commit("SET_CONTRACT", {});
  },
  resetContractKeys(context, keys) {
    const { contract } = context.state;
    keys.forEach(key => {
      delete contract[key];
    });
    context.commit("SET_CONTRACT", contract);
  },
  updateContract(context, data) {
    const { contract } = context.state;
    Object.assign(contract, data);
    context.commit("SET_CONTRACT", contract);
  },
  sendNewPatientRequest(context, patient) {
    return api.createOaldRequest({ patient });
  },
  sendNewContractRequest(context, data) {
    const { newPatientContract } = data;

    return api.createOaldRequest({
      patient: newPatientContract.patient,
      prescription: newPatientContract.prescription,
      administrativeFiles: newPatientContract.administrativeFiles,
      documentId: newPatientContract.documentId
    });
  },
  fetchPatient(context, patientId) {
    return new Promise((resolve, reject) => {
      fetchPatient(patientId)
        .then(res => {
          resolve(formatPatientName(res.data.body));
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  printRenewalDocument(context, data) {
    const { contract } = context.state;
    const fillInformations = { ...data, installDate: contract.prescription.installDate, initial: true };
    return new Promise((resolve, reject) => {
      fillDocument({
        documentType: renewalDocumentTypes.dap,
        fillInformations
      })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  signRenewalDocument(context, data) {
    return new Promise((resolve, reject) => {
      if (!context.rootState.login.user.rpps) {
        return reject(new Error("Current user hasn't a rpps"));
      }

      if (data?.patient?.ssid?.length !== 15) {
        return reject({
          data: {
            errorMessage: "Merci de vous assurer que le patient dispose d'un numéro de sécurité sociale conforme composé de 15 caractères"
          }
        });
      }

      const { documentType, ...fillInformations } = data;
      const { rpps, renewalInfos } = context.rootState.login.user;
      const { contract } = context.state;

      fillInformations.patientFirstname = fillInformations.patient.firstname;
      fillInformations.patientLastname = fillInformations.patient.lastname;
      fillInformations.installDate = contract.prescription.installDate;

      return api
        .signDocumentFromOALD({
          documentType,
          rpps,
          assignedProId: renewalInfos.ordoclicId,
          fillInformations
        })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
