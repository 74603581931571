const Oald = () => import(/* webpackChunkName: "oald" */ "@/modules/oald");
const OaldNewPatientContract = () => import(/* webpackChunkName: "oald" */ "@/modules/oald/_components/OaldNewPatientContract");

export default [
  {
    path: "prescription",
    component: Oald,
    redirect: "prescription/patient/new",
    children: [
      {
        path: "prescription/patient/new",
        alias: "prescription/patient/nouveau",
        name: "oaldNewPatientContract",
        props: true,
        component: OaldNewPatientContract
      }
    ]
  }
];
