import { fetchPublication } from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchPublication(context, id) {
    return new Promise((resolve, reject) => {
      fetchPublication(id)
        .then(response => {
          if (!response?.data?.body) {
            return reject();
          }
          resolve(response.data.body);
        })
        .catch(error => reject(error));
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
