<template functional>
  <div
    class="loader--basic"
    :class="`${$options.methods.getSizeClassName(props.size)} ${$options.methods.getColorClassName(props.color)}`"
  >
    <span></span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: String,
      default: "m"
    },
    color: {
      type: String,
      default: ""
    }
  },
  methods: {
    getSizeClassName(size) {
      return size === "s" ? "loader--basic--small" : "";
    },
    getColorClassName(color) {
      return color === "nc--00" ? "loader--basic--nc--00" : "";
    }
  }
};
</script>
